<template>
  <div class="w-100 p-l-30 p-r-30">
    <div class="card mt-3 mx-auto">
      <div class="card-header color-33 bg-transparent">
        <img :alt="tournamentName" src="../assets/images/racket-gray.svg">
        <span class="p-l-10">{{ tournamentName }}</span>
      </div>
      <div class="card-body px-2">
        <div class="row">
          <p class="mx-auto col-xl-6 col-lg-5 col-md-7 scan-title mt-5">
            スキャナーでQRコードを読み込んでください。もしくは、入場管理番号を直接入力してください。 <br>※半角英数字で入力にしてください。
          </p>
        </div>
        <div class="row mt-4">
          <div class="mx-auto width-350">
            <div class="input-group mt-1">
              <input id="scanForm"
                     v-model="userScanObject.userId"
                     class="form-control py-2 color-33 border-right-0 height-48"
                     placeholder="入場管理番号"
                     type="search"
                     @keydown.enter="onKeyDown"/>
              <span class="input-group-append">
              <button class="btn-submit width-48 height-48 input-group-text bg-transparent" type="submit"
                      @click="getUser">
                <i class="fa fa-search"></i>
              </button>
            </span>
            </div>
          </div>

        </div>

        <div class="scan-box m-t-30 m-b-30">
          <div v-if="errors['userId']">
            <div class="row mx-auto">
              <h5 class="font-20 user-id mt-5 text-center">{{ requestUserId }}</h5>
            </div>
            <div class="row">
              <p class="mx-auto col-5 mt-2 mb-5 text-center">
                {{ errors['userId'] }}
              </p>
            </div>
          </div>

          <div v-if="loading" class="row">
            <div class="col m-t-40 m-b-5">
              <DotLoader></DotLoader>
            </div>
          </div>

          <div v-if="typeof response !== 'undefined' && typeof response.user !== 'undefined' && enterStatus">
            <div class="card card-detail-bg mt-3 col-9 mx-auto">
              <div class="card-body pl-3">
                <div class="card-holder">
                  <div class="row">
                    <div class="col card-detail-status enterStatus">
                      <h5 class="color-88 font-12">入場可否</h5>
                      <div v-html="showEnterStatus(response.enterStatus)"></div>
                    </div>
                    <div class="col mt-4 data-info">
                      <div class="row p-l-10">
                        <div class="col p-l-0">
                          <h5 class="color-88 font-12 text-nowrap">姓名</h5>
                          <h5 class="color-33 font-16 text-nowrap text-truncate">
                            　{{ response.user.lastName }} {{ response.user.firstName }}
                          </h5>
                        </div>
                        <div class="col p-l-0">
                          <h5 class="color-88 font-12 text-nowrap">入場管理番号</h5>
                          <h5 class="color-33 font-16 text-nowrap">
                            {{ response.user.userId }}
                          </h5>
                        </div>
                        <div class="col p-l-0">
                          <h5 class="color-88 font-12 text-nowrap">ロール</h5>
                          <h5 class="color-33 font-16 text-nowrap">{{ userRole }}</h5>
                        </div>
                        <div class="col p-l-0">
                        </div>
                      </div>
                      <div class="row p-l-10 mt-2">
                        <div class="col p-l-0">
                          <h5 class="color-88 font-12">ステータス</h5>
                          <template>
                            <div class="d-inline-flex mb-3" v-html="populateReasons()">
                            </div>
                          </template>
                        </div>
                        <template v-if="response.heatStrokeScore">
                          <div class="col p-l-0">
                            <h5 class="color-88 font-12 text-nowrap">熱中症点数</h5>
                            <h5 class="color-33 font-16 text-nowrap">
                              {{ response.heatStrokeScore + '点/40点中' }}
                            </h5>
                          </div>
                          <div class="col p-l-0"></div>
                        </template>
                        <div class="col p-l-0">
                          <h5 class="color-88 font-12 text-nowrap">申告事項</h5>
                          <h5 class="color-33 font-16 text-nowrap">{{organization}}</h5>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col">
                          <h5 class="color-88 font-12 text-nowrap">ワクチン接種</h5>
                          <h5 class="color-33 font-16 text-nowrap">{{ coronaVaccinationCount }}</h5>
                        </div>
                        <div class="col">
                          <h5 class="color-88 font-12 text-nowrap">ワクチン接種日</h5>
                          <h5 class="color-33 font-16 text-nowrap">{{ response.user.latestCoronaVaccinationDate}}</h5>
                        </div>
                        <div class="col">
                          <h5 class="color-88 font-12 text-nowrap">PCR検査</h5>
                          <h5 class="color-33 font-16 text-nowrap">{{ latestPcrTestResult}}</h5>
                        </div>
                        <div class="col">
                          <h5 class="color-88 font-12 text-nowrap">PCR検査日</h5>
                          <h5 class="color-33 font-16 text-nowrap">{{ response.user.latestPcrTestDate}}</h5>
                       </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <!--          <div v-if="typeof response !== 'undefined' && typeof response.user !== 'undefined' && enterStatus"-->
          <!--               class="card-detail">-->
          <!--            <div class="col-md-9 col-sm-12 mx-auto">-->
          <!--              <div class="card card-detail-bg">-->
          <!--                <div class="card-body">-->
          <!--                  <div class="row">-->
          <!--                    <div class="col card-detail-status">-->
          <!--                      <h5 class="color-88 font-12">入場可否</h5>-->
          <!--                      <div v-html="showEnterStatus(response.enterStatus)"></div>-->
          <!--                    </div>-->
          <!--                    <div class="col card-detail-info p-t-25">-->
          <!--                      <div class="row">-->
          <!--                        <div class="col p-l-0">-->
          <!--                          <h5 class="color-88 font-12">姓名</h5>-->
          <!--                          <h5 class="color-33 font-16">{{ response.user.lastName + ' ' + response.user.firstName }}</h5>-->
          <!--                        </div>-->
          <!--                        <div class="col p-l-0">-->
          <!--                          <h5 class="color-88 font-12">入場管理番号</h5>-->
          <!--                          <h5 class="color-33 font-16">{{ response.user.userId }}</h5>-->

          <!--                        </div>-->
          <!--                        <div class="col p-l-0">-->
          <!--                          <h5 class="color-88 font-12">ロール</h5>-->
          <!--                          <h5 class="color-33 font-16">{{ userRole }}</h5>-->
          <!--                        </div>-->
          <!--&lt;!&ndash;                        <div class="col"></div>&ndash;&gt;-->
          <!--                      </div>-->
          <!--                      <div class="row">-->
          <!--                        <div class="col p-l-0">-->
          <!--                          <h5 class="color-88 font-12">ステータス</h5>-->

          <!--                          <div class="d-inline-flex" v-html="populateReasons()">-->
          <!--                          </div>-->
          <!--                        </div>-->

          <!--                      </div>-->
          <!--                    </div>-->
          <!--                  </div>-->
          <!--                </div>-->
          <!--              </div>-->
          <!--            </div>-->
          <!--          </div>-->
          <!--        </div>-->

          <div class="row m-t-15">
            <button v-show="foundUser" class="width-350 mx-auto btn admin-home-custom-button"
                    v-bind:disabled="!foundUser"
                    @click="navigateUserRecord">詳細
            </button>
          </div>
        </div>
      </div>

    </div>
  </div>
</template>

<script>
import Modal from '../components/modal/Modal'
import {apiConfig, defaults} from '@/constants'
import {mapGetters} from 'vuex'
import DotLoader from "@/components/loaders/DotLoader"
import Common from "jsadminclient/common"
import Helper from "jsadminclient/helper"

export default {
  name: "Scan",
  components: {DotLoader, Modal},
  props: {
    tournamentId: {type: Number, default: null},
    defaultResponse: {type: Object, default: null},
    defaultRecordResponse: {type: Object, default: null},
  },
  data() {
    return {
      apiConfig: apiConfig,
      defaults: defaults,
      tournamentName: '',
      loading: false,
      waiting: true,
      ifQrNotFound: true,
      isCheckbox: true,
      requestUserId: "",
      userRole: "",
      organization: "",
      hasCoronaVaccination:"",
      coronaVaccinationCount: null,
      latestPcrTestResult:"",
      userScanObject: {
        userId: null
      },
      enterStatus: '',
      response: [],
      errors: []
    }
  },
  created() {
    this.apiConfig.headers['X-API-Token'] = this.config.token
  },
  mounted() {
    this.ifQrNotFound = true
    this.getTournament()

    // Detect Barcode scanner
    $('body').on('keydown', (event) => {
      $('#scanForm').focus()
    })
    $('#scanForm').on('input', (event) => {
      const text = event.target.value

      if (text.length > 1
          && text.substr(text.length - 1) === 'T') {
        Apv.$set(this.userScanObject, "userId", "T") // Last input is T
      } else if (text.length >= 7
          && text.substr(text.length - 7, 1) === 'T') {
        // Ex: T000001T000002
        // Fix IME ON. Get the character behind.
        Apv.$set(this.userScanObject, "userId", this.toHalfWidth(text.substr(text.length - 7)))
      }
    })
  },
  beforeDestroy() {
    // Remove temporarily changed CSS
    $('#main').css('background-color', '');
    $('#scanForm').off('input')
    $('body').off('keydown')
  },
  computed: {
    ...mapGetters(['config']),
    foundUser() {
      if (this.loading) {
        return false
      }
      return !!(this.response.user && this.response.user.userId)

    },
  },
  methods: {
    getApiConfig() {
      return new AdminApi.ApiConfig(
          this.apiConfig.basePath,
          this.apiConfig.headers
      )
    },
    toHalfWidth(val) {
      return val.replace(/[Ａ-Ｚａ-ｚ０-９！-～]/g, (s) => {
        return String.fromCharCode(s.charCodeAt(0) - 0xFEE0);
      });
    },
    showEnterStatus(enterStatus, extraCss = '') {
      return Helper.showEnterStatus(enterStatus, extraCss)
    },
    async onKeyDown(event) {
      if (event.keyCode !== 13) {
        return
      }
      if (this.loading === true) {
        return
      }
      await this.getUser()
    },
    recognizeEnterStatus() {
      const data = this.response
      // enterStatus: 0 = No input, 1 = Good, 2 = Bad,
      const noInputSpecifiedInBadReasons = data.badReasons && data.badReasons.length === 1 && data.badReasons.includes('未入力')
      let enterStatus = data.enterStatus
      // const inputCount = data.items.length - data.items.filter(input => {
      //   return input.halthStatus === 3 || !input.bodyTemp
      // }).length

      if (/*inputCount === 0 || */noInputSpecifiedInBadReasons) {
        enterStatus = 0
      }

      this.enterStatus = defaults.enterStatus[enterStatus]
    },
    populateReasons() {
      const data = this.response
      let html = ''
      for (let key in defaults.enterStatus.reasons) {
        let reason = defaults.enterStatus.reasons[key]
        if (data.badReasons.includes(reason.value)) {
          html += '<div>' +
              '<span class="badge badge-status m-r-15 border-0 height-28 width-104 ' + reason.button + '">' +
              '<i class="' + reason.icon + '"></i>' + reason.value +
              '</span>' +
              '</div>'
        }
      }

      return html

    },
    navigateUserRecord() {
      const params = {
        userId: this.userScanObject.userId,
        tournamentId: Number(this.tournamentId),
      }
      this.$router.push({
        name: 'UserRecord',
        params: params,
        query: Common.appendSearchQuery(this.createSearchQuery(), {
          fromScan: true
        })
      })
    },
    async getTournament() {
      try {
        const api = new AdminApi.TournamentApi(this.getApiConfig())
        var response = await api.tournamentsListTournamentIdGetAsync(this.tournamentId)
        this.tournamentName = response.tournamentName
      } catch (error) {
        const isTokenError = Common.isTokenError(error.response)
        if (isTokenError) {
          this.$router.push({name: 'Login'});

        } else {
          Common.handleAPIError(error)
        }
      }
    },
    async getUser() {
      try {
        this.errors = []
        this.userRole = ""
        this.organization = ""
        this.loading = true
        this.waiting = true
        this.requestUserId = this.userScanObject.userId
        var main = $('main')
        // Remove temporarily changed CSS
        main.css('background-color', '');

        this.response = []
        const api = new AdminApi.TournamentApi(this.getApiConfig())
        var response = await api.tournamentsTournamentIdScanPostAsync(
            this.tournamentId,
            this.userScanObject
        )
        if (response) {
          this.loading = false
          this.waiting = false
          this.response = response
          this.recognizeEnterStatus()

          // Set main background
          if (response.enterStatus == 1) {
            $('#main').css({'cssText': 'background-color: #7EC100 !important;'});
          } else {
            $('#main').css({'cssText': 'background-color: #C1001B !important;'});
          }
          this.user = this.response.user
          if (this.response.userRole) {
            this.userRole = this.response.userRole
          }

          if(this.response.organization){
            this.organization = this.response.organization
          }
          this.coronaVaccinationCount = Common.getHasCoronaVaccination1(response.user.coronaVaccinationCount)
          this.latestPcrTestResult = Common.getLatestPcrTestResult(response.user.latestPcrTestResult)
        }

      } catch (error) {
        this.errors = Common.getErrorList(error.response, this.errors)
        const isTokenError = Common.isTokenError(error.response)
        if (isTokenError) {
          this.$router.push({name: 'Login'})
          return
        } else {
          Common.handleAPIError(error)
        }

        this.user = []
        this.loading = false
        this.waiting = false
      }
    },
    createSearchQuery() {
      return Common.appendSearchQuery(this.$route.query)
    },
  }
}
</script>

<style lang="scss" scoped>
.qr-input {
  width: 45% !important;
}

::placeholder {
  color: #C7AEB1;
}

.admin-home-custom-button {
  background: #C95D6C;
  color: #FFFFFF;
  width: 45%;
  height: 44px !important;
}

.icon-success {
  left: calc(50% - 68px) !important;
}

.text-success {
  color: #7EC100 !important;
}

.user-id {
  color: #E0B1B7;
  font-weight: bold;
}

.scan-box {
  min-height: 206px
}

.card {
  .input-group {
    &-append {
      .btn-submit {
        border-top-left-radius: 0 !important;
        border-bottom-left-radius: 0 !important;
        padding-top: 10px;
        padding-left: 18px;
        margin-left: 1px;
      }
    }
  }

  &-detail {
    background-color: transparent !important;
    border: none !important;

    &-bg {
      min-height: 200px;
      background-color: #FAF7F9;
      border-color: #DACED1;
    }

    &-status {
      height: 143px;
      max-width: 163px;

      &-icon {
        background: #ffffff;
        border: 1px solid #eff1f3;
        height: 139px;
        width: 139px;
        position: relative;
      }

      i {
        font-size: 111px;
        color: #c1001b;
        position: absolute;
        top: calc(50% - 55.5px);
        left: calc(50% - 55.5px);
        padding-left: 18px;
      }
    }

    &-info {
      .width-128 {
        max-width: 128px;
      }
    }
  }

  .badge {
    &-status {
      margin-top: 12px;
    }
  }
}

.scan-title {
  width: 541.5px;
}

@media only screen and (max-width: 992px), (max-width: 768px), (max-width: 1024px) {
  .scan-title {
    max-width: 333px;
  }
}


</style>
